<template>
  <div class="AshDeposite">
    <div class="banner">
      <img src="@/assets/images/parkBanner.png" alt="" />
    </div>
    <div class="container">
      <Form
        :model="formItem"
        :label-width="100"
        ref="formItem"
        :rules="rulesForm"
      >
        <FormItem label="亲属姓名：" prop="name">
          <Input v-model="formItem.name" placeholder="请输入预约人姓名"></Input>
        </FormItem>
        <FormItem label="联系方式：" prop="phone">
          <Input
            v-model="formItem.phone"
            placeholder="请输入预约人联系方式"
          ></Input>
        </FormItem>
        <FormItem label="地址：" prop="address">
          <Input v-model="formItem.address" placeholder="请输入地址"></Input>
        </FormItem>
        <FormItem label="寄存日期：" prop="targetTime">
          <DatePicker
            type="date"
            v-model="formItem.targetTime"
            placeholder="请选择寄存日期"
          ></DatePicker>
        </FormItem>
        <FormItem label="备     注：" prop="remarks">
          <Input
            v-model="formItem.remarks"
            type="textarea"
            placeholder="请输入备注"
          ></Input>
        </FormItem>
        <FormItem label="预约项目：">
          <!-- <Input v-model="formItem.input" placeholder="请输入预约人姓名"></Input> -->
          <ul class="projectList">
            <li v-for="(item, idx) in list" :key="idx" @click="jcClick(item)" :style="(jcid == item.id) ? 'border:2px solid #299654;':''">
              <img class="projectImg" :src="item.url" />
              <div class="projectName">{{ item.name }}</div>
              <div class="projectPrice">
                ¥{{ item.price }}
                <Tooltip
                  placement="top"
                  max-width="200"
                  theme="light"
                  :content="item.descript"
                >
                  <span>详情</span>
                  <!-- <div slot="content">
                    <p>388元鲜花套餐内容：1个花篮，1盒水果，4盘点心，祈福卡片，擦碑服务，拍摄照片及视频发送至客户微信。</p>
                  </div> -->
                </Tooltip>
                <s
              </div>
            </li>
          </ul>
        </FormItem>
        <FormItem label="">
          <div class="submitBtn" @click="handleSubmit()">提交</div>
        </FormItem>
      </Form>
    </div>
  </div>
</template>

<script>
import api from "@/util/api";
export default {
  data() {
    return {
      companyId: this.$route.query.companyId || 0,
      list: [],
      formItem: {},
      jcid:null,
      jcName:null,
      rulesForm: {
        name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],
        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号码格式不正确",
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: "请输入地址",
            trigger: "blur",
          },
        ],
        targetTime: [
           { required: true, type: 'date', message: '请选择日期', trigger: 'change' }
        ],
        remarks: [
          {
            required: true,
            message: "请输入地址",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.search();
  },
  methods: {
    search() {
      api.apiOrderAshesMealList({ companyId: this.companyId }, (res) => {
        this.list = res.data;
      });
    },
    jcClick(item){
      this.jcid = item.id
      this.jcName= item.name
    },
    handleSubmit() {
      if(this.jcid == null){
        return this.$Message.info('请选择预约项目');
      }
      this.$refs.formItem.validate((valid) => {
        if (valid) {
          let formItem ={...this.formItem}
          formItem.targetTime = new Date(formItem.targetTime).getTime();
          formItem.companyId = this.companyId;
          formItem.orderInfo = this.jcName;
          formItem.status = 1, //是否处理:0处理 1未处理
          api.apiOrderAshesMealSubmit(formItem,res=>{
          this.$Message.success("提交成功!");
          this.jcid = null;
          this.jcName = null;
          this.formItem ={}
          })
        } else {
          // this.$Message.error("Fail!");
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.AshDeposite {
  .banner {
    height: 220px;
  }
  .container {
    // height: 660px;
    background: #ffffff;
    border-radius: 2px;
    margin: 24px auto 40px;
    padding: 24px;
    /deep/.ivu-input,
    .ivu-date-picker {
      width: 284px;
    }
    /deep/.ivu-input-type-textarea {
      textarea {
        width: 458px;
        height: 118px;
      }
    }
    .projectList {
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;
      li {
        width: 161px;
        height: 222px;
        border: 1px solid #979797;
        margin-right: 21px;
        overflow: hidden;
        .projectImg {
          width: 160px;
          height: 160px;
        }
        .projectName {
          height: 25px;
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #4d4d4d;
          line-height: 25px;
          margin: 4px 8px 0;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .projectPrice {
          font-size: 24px;
          font-weight: 400;
          color: #e64747;
          line-height: 33px;
          letter-spacing: 1px;
          margin: 0 8px 0;
          display: flex;
          align-items: center;
          /deep/.ivu-tooltip {
            margin-left: auto;
            display: inline-block;
            font-size: 14px;
            font-weight: 400;
            color: #6085bb;
            line-height: 20px;
            cursor: pointer;
          }
        }
      }
    }
    .submitBtn {
      width: 116px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background: #299654;
      border-radius: 2px;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      letter-spacing: 2px;
      cursor: pointer;
      &:active {
        opacity: 0.8;
      }
    }
  }
}
</style>